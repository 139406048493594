@import "mixin";

// デザイン
.col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9 {
  padding: 5px 7px;
}

.popover {
  color: #217cbd;
}

.action-icon .glyphicon {
  color: #4289ef;
  font-size: 20px;
  margin: 0px 2px;
}

textarea.form-control {
  height:75px;
  max-width:100%;
}

.serverMsgArea {
  margin: 10px;
}

.btn-area {
  margin-top: 10px;
  margin-bottom: 10px;

  button {
    margin: 0px 10px;
  }

  &.center-btn {
    text-align: center;
  }
}

.preview-area {
  padding:15px 30px;

  a.btn-sm {
    padding: 8px 5px;
  }

  .form-area img {
    border: 1px solid #d8d8d8;
    max-width: 37vw;
    max-height: 70vh;
  }
}

#dropArea {
  width: 100%;
  padding: 10px;
  background: #eee;
  text-align: center;
  font-size: 12px;
}

#modal-preview {
  .modal-content {
    max-height: 95vh;
    min-height: 600px;
  }

  .form-info {
    padding: 0px 15px;

    table {
      min-width: auto;
      margin-bottom: 5px;
      table-layout: fixed;

      th, td {
        font-weight: normal;
      }

      .title {
        width: 24%;
        background-color: #f7f7f7;
        font-weight: bold;
      }

      .ellipsis {
        width: 70%;
        @include ellipsis;
      }

      .action-icon {
        width: 6%;
        padding: 15px 3px;
        text-align: center;
      }
    }
  }

  .preview-area {
    text-align: center;
    padding: 0px 30px;

    img {
      max-height: 55vh;
      min-height: 350px;
    }
  }

  .modal-footer {
    text-align: center;
    border-top: none;
  }
}

#modal-preview-detail {
  .modal-content {
    min-height: auto;

    th, td {
      font-weight: normal;
    }

    table {
      .title {
        width: 100px;
        background-color: #f7f7f7;
        font-weight: bold;
      }
    }
  }
}


.alert {
  &.alert-info {
    border: dashed 2px #286090;
  }
  &.alert-danger {
    border: dashed 2px #DC143C;
  }
  &.alert-warning {
    border: dashed 2px orange;
  }
}

.main-title {
  min-height: 20px;
}


table.table-headerfixed {
  overflow: auto;
  table-layout: fixed;

  thead, tbody, tr, td, th {
    display: block;
  }

  tr:after {
    content: ' ';
    display: block;
    visibility: hidden;
    clear: both;
  }

  tbody {
    max-height: 55vh;
    overflow-y: auto;

    .col-result {
      text-align: center;
    }
  }

  tbody td, thead th {
    float: left;
    height: 38px;
  }
}

#loading {
  .modal-dialog {
    top: 50%;
    margin: 0px auto;

    @media (max-width: 767px) {
      width: 250px;
    }
  }

  .loading-content {
    text-align: center;
  }

  #loader {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    border: 5px dashed #1782dd;
    display: inline-block;
    @include spin($time:1.5s);
  }

  .loading-msg {
    margin-top: 4px;
    @include blinking($time:0.75s);
  }
}


// 汎用
.bg-white {
  background-color: #fff;
}

.bg-grey {
  background-color: #f5f5f5;
}

.required, .note {
  color: red;
}

.bold {
  font-weight: bold;
}

.margin-off {
  margin: 0px;
}

.break-all {
  word-break: break-all;
}

.display-none {
  display: none !important;
}

.display-block {
  display: block !important;
}

.display-inline {
  display: inline !important;
}

.display-inline-block {
  display: inline-block !important;
}

.normal-label {
  font-weight: normal;
}

.icon-warn {
  height:20px;
  width:20px;
}

.icon-error {
  height:20px;
  width:20px;
}

