footer {
  border-top: 1px solid #e7e7e7;
  margin-top: 10px;
}

.footer-content {
  .copyright {
    margin: 15px 40px;
    float: right;
  }
}
