@charset "UTF-8";
/* アニメーション */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    opacity: 0.2; }
  50% {
    -webkit-transform: rotate(180deg);
    opacity: 1; }
  100% {
    -webkit-transform: rotate(360deg);
    opacity: 0.2; } }

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    opacity: 0.2; }
  50% {
    -moz-transform: rotate(180deg);
    opacity: 1; }
  100% {
    -moz-transform: rotate(360deg);
    opacity: 0.2; } }

@-ms-keyframes spin {
  0% {
    -ms-transform: rotate(0deg);
    opacity: 0.2; }
  50% {
    -ms-transform: rotate(180deg);
    opacity: 1; }
  100% {
    -ms-transform: rotate(360deg);
    opacity: 0.2; } }

@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg);
    opacity: 0.2; }
  50% {
    -o-transform: rotate(180deg);
    opacity: 1; }
  100% {
    -o-transform: rotate(360deg);
    opacity: 0.2; } }

@keyframes spin {
  0% {
    transform: rotate(0deg);
    opacity: 0.2; }
  50% {
    transform: rotate(180deg);
    opacity: 1; }
  100% {
    transform: rotate(360deg);
    opacity: 0.2; } }

@-webkit-keyframes blink {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }

@-moz-keyframes blink {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }

@keyframes blink {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }

.form-list table {
  min-width: 800px; }
  .form-list table thead {
    color: white; }
  .form-list table.table-headerfixed .col-formname {
    width: 20%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
    .form-list table.table-headerfixed .col-formname .popover {
      max-width: none;
      width: auto;
      word-break: break-all; }
  .form-list table.table-headerfixed .col-formtext {
    width: 30%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
  .form-list table.table-headerfixed .col-updater {
    width: 15%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
  .form-list table.table-headerfixed .col-updatedate {
    width: 20%; }
  .form-list table.table-headerfixed .col-action {
    width: 15%; }

.change-rect {
  margin-top: 20px; }
  .change-rect a span.required {
    font-size: 14px; }
  .change-rect .rect-item {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    border: 1px solid #ddd;
    background-color: white;
    color: #555;
    text-decoration: none; }
  .change-rect .target-rect {
    border: 4px solid red;
    background-color: #f2dede; }
  .change-rect .required-rect {
    background-color: #fcf8e3; }
  .change-rect .icon-warn {
    float: right; }
  .change-rect .cancel-rect {
    float: right;
    position: relative;
    top: -7px; }
  .change-rect .rect-fixed-pos {
    height: 42px;
    padding: 11px 0px;
    margin-bottom: -1px; }
  .change-rect .select-rect {
    height: 48px;
    padding: 14px 0px;
    margin-bottom: -1px; }

.form-file-select .alert {
  width: 100%;
  margin-bottom: 5px; }

.form-file-select .preview-area .form-area {
  clear: both; }
  .form-file-select .preview-area .form-area .preview-nav::before, .form-file-select .preview-area .form-area ::after {
    clear: none !important; }
  .form-file-select .preview-area .form-area .rotate-button {
    padding-bottom: 8px;
    float: right;
    outline: none; }
    .form-file-select .preview-area .form-area .rotate-button .rotate-icon-area {
      position: relative; }
      .form-file-select .preview-area .form-area .rotate-button .rotate-icon-area .rotate-icon {
        position: relative;
        display: inline-block;
        padding: 0 0 0 16px;
        color: #fff;
        vertical-align: middle;
        text-decoration: none;
        font-size: 15px; }
      .form-file-select .preview-area .form-area .rotate-button .rotate-icon-area .rotate-icon::before {
        position: absolute;
        margin: auto;
        content: "";
        vertical-align: middle;
        top: 13px;
        bottom: 0;
        left: 12px;
        box-sizing: border-box;
        width: 5px;
        height: 5px;
        border: 7px solid transparent;
        border-left: 10px solid #fff;
        -webkit-transform: rotate(40deg);
        transform: rotate(40deg); }
      .form-file-select .preview-area .form-area .rotate-button .rotate-icon-area .rotate-icon::after {
        position: absolute;
        top: 5px;
        bottom: 0;
        margin: auto;
        content: "";
        vertical-align: middle;
        left: -1px;
        border: 0 solid transparent;
        border-top: 5px solid #fff;
        border-radius: 10px 0 0;
        width: 16px;
        height: 10px;
        -webkit-transform: rotate(34deg);
        transform: rotate(34deg); }

@-moz-document url-prefix() {
  .form-file-select .preview-area .form-area .rotate-button .rotate-icon-area .rotate-icon::before {
    top: 5px; } }

@-moz-document url-prefix() {
  .form-file-select .preview-area .form-area .rotate-button .rotate-icon-area .rotate-icon::after {
    top: 2px; } }
      .form-file-select .preview-area .form-area .rotate-button .rotate-icon-area .rotate-angle {
        position: absolute;
        color: #fff;
        font-weight: 500;
        font-size: 11px;
        top: -2px;
        left: 4px;
        text-shadow: 1px 1px 0 #337ab7, -1px 1px 0 #337ab7; }
  .form-file-select .preview-area .form-area .tab-content {
    clear: both; }

.form-file-select .btn-area {
  clear: both; }

.input-area .glyphicon, .confirm-area .glyphicon {
  font-size: 10px;
  margin: 0 5px 0 0; }

.form-confirm .preview-area .form-area img {
  max-height: 100%;
  max-width: 100%; }

.form-confirm .alert {
  width: 100%;
  margin-bottom: 5px; }

.form-confirm .form-area {
  user-select: none;
  /* CSS3 */
  -moz-user-select: none;
  /* Firefox */
  -webkit-user-select: none;
  /* Safari、Chromeなど */
  -ms-user-select: none;
  /* IE10から */ }

.form-confirm .bg-img {
  width: 550px;
  border: solid #999999 1px;
  position: relative; }

.form-confirm .bg-canvas {
  background-color: transparent;
  position: absolute;
  top: 0px;
  left: 0px; }

.form-edit .preview-area .form-area img {
  max-height: 100%;
  max-width: 100%; }

.form-edit .alert {
  width: 100%;
  margin-bottom: 5px; }

.form-edit .form-area {
  user-select: none;
  /* CSS3 */
  -moz-user-select: none;
  /* Firefox */
  -webkit-user-select: none;
  /* Safari、Chromeなど */
  -ms-user-select: none;
  /* IE10から */ }

.form-edit .bg-img {
  width: 550px;
  border: solid #999999 1px;
  position: relative; }

.form-edit .bg-canvas {
  background-color: transparent;
  position: absolute;
  top: 0px;
  left: 0px; }

.form-preview .preview-area .forms {
  width: 50%;
  float: left; }

.form-file-name {
  padding-top: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }
