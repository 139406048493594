@charset "UTF-8";
/* アニメーション */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    opacity: 0.2; }
  50% {
    -webkit-transform: rotate(180deg);
    opacity: 1; }
  100% {
    -webkit-transform: rotate(360deg);
    opacity: 0.2; } }

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    opacity: 0.2; }
  50% {
    -moz-transform: rotate(180deg);
    opacity: 1; }
  100% {
    -moz-transform: rotate(360deg);
    opacity: 0.2; } }

@-ms-keyframes spin {
  0% {
    -ms-transform: rotate(0deg);
    opacity: 0.2; }
  50% {
    -ms-transform: rotate(180deg);
    opacity: 1; }
  100% {
    -ms-transform: rotate(360deg);
    opacity: 0.2; } }

@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg);
    opacity: 0.2; }
  50% {
    -o-transform: rotate(180deg);
    opacity: 1; }
  100% {
    -o-transform: rotate(360deg);
    opacity: 0.2; } }

@keyframes spin {
  0% {
    transform: rotate(0deg);
    opacity: 0.2; }
  50% {
    transform: rotate(180deg);
    opacity: 1; }
  100% {
    transform: rotate(360deg);
    opacity: 0.2; } }

@-webkit-keyframes blink {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }

@-moz-keyframes blink {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }

@keyframes blink {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }

.col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9 {
  padding: 5px 7px; }

.popover {
  color: #217cbd; }

.action-icon .glyphicon {
  color: #4289ef;
  font-size: 20px;
  margin: 0px 2px; }

textarea.form-control {
  height: 75px;
  max-width: 100%; }

.serverMsgArea {
  margin: 10px; }

.btn-area {
  margin-top: 10px;
  margin-bottom: 10px; }
  .btn-area button {
    margin: 0px 10px; }
  .btn-area.center-btn {
    text-align: center; }

.preview-area {
  padding: 15px 30px; }
  .preview-area a.btn-sm {
    padding: 8px 5px; }
  .preview-area .form-area img {
    border: 1px solid #d8d8d8;
    max-width: 37vw;
    max-height: 70vh; }

#dropArea {
  width: 100%;
  padding: 10px;
  background: #eee;
  text-align: center;
  font-size: 12px; }

#modal-preview .modal-content {
  max-height: 95vh;
  min-height: 600px; }

#modal-preview .form-info {
  padding: 0px 15px; }
  #modal-preview .form-info table {
    min-width: auto;
    margin-bottom: 5px;
    table-layout: fixed; }
    #modal-preview .form-info table th, #modal-preview .form-info table td {
      font-weight: normal; }
    #modal-preview .form-info table .title {
      width: 24%;
      background-color: #f7f7f7;
      font-weight: bold; }
    #modal-preview .form-info table .ellipsis {
      width: 70%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    #modal-preview .form-info table .action-icon {
      width: 6%;
      padding: 15px 3px;
      text-align: center; }

#modal-preview .preview-area {
  text-align: center;
  padding: 0px 30px; }
  #modal-preview .preview-area img {
    max-height: 55vh;
    min-height: 350px; }

#modal-preview .modal-footer {
  text-align: center;
  border-top: none; }

#modal-preview-detail .modal-content {
  min-height: auto; }
  #modal-preview-detail .modal-content th, #modal-preview-detail .modal-content td {
    font-weight: normal; }
  #modal-preview-detail .modal-content table .title {
    width: 100px;
    background-color: #f7f7f7;
    font-weight: bold; }

.alert.alert-info {
  border: dashed 2px #286090; }

.alert.alert-danger {
  border: dashed 2px #DC143C; }

.alert.alert-warning {
  border: dashed 2px orange; }

.main-title {
  min-height: 20px; }

table.table-headerfixed {
  overflow: auto;
  table-layout: fixed; }
  table.table-headerfixed thead, table.table-headerfixed tbody, table.table-headerfixed tr, table.table-headerfixed td, table.table-headerfixed th {
    display: block; }
  table.table-headerfixed tr:after {
    content: ' ';
    display: block;
    visibility: hidden;
    clear: both; }
  table.table-headerfixed tbody {
    max-height: 55vh;
    overflow-y: auto; }
    table.table-headerfixed tbody .col-result {
      text-align: center; }
  table.table-headerfixed tbody td, table.table-headerfixed thead th {
    float: left;
    height: 38px; }

#loading .modal-dialog {
  top: 50%;
  margin: 0px auto; }
  @media (max-width: 767px) {
    #loading .modal-dialog {
      width: 250px; } }

#loading .loading-content {
  text-align: center; }

#loading #loader {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  border: 5px dashed #1782dd;
  display: inline-block;
  -webkit-animation: spin 1.5s linear infinite;
  -moz-animation: spin 1.5s linear infinite;
  -ms-animation: spin 1.5s linear infinite;
  -o-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite; }

#loading .loading-msg {
  margin-top: 4px;
  -webkit-animation: blink 0.75s ease-in-out infinite alternate;
  -moz-animation: blink 0.75s ease-in-out infinite alternate;
  animation: blink 0.75s ease-in-out infinite alternate; }

.bg-white {
  background-color: #fff; }

.bg-grey {
  background-color: #f5f5f5; }

.required, .note {
  color: red; }

.bold {
  font-weight: bold; }

.margin-off {
  margin: 0px; }

.break-all {
  word-break: break-all; }

.display-none {
  display: none !important; }

.display-block {
  display: block !important; }

.display-inline {
  display: inline !important; }

.display-inline-block {
  display: inline-block !important; }

.normal-label {
  font-weight: normal; }

.icon-warn {
  height: 20px;
  width: 20px; }

.icon-error {
  height: 20px;
  width: 20px; }
