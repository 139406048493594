@charset "UTF-8";
/* アニメーション */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    opacity: 0.2; }
  50% {
    -webkit-transform: rotate(180deg);
    opacity: 1; }
  100% {
    -webkit-transform: rotate(360deg);
    opacity: 0.2; } }

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    opacity: 0.2; }
  50% {
    -moz-transform: rotate(180deg);
    opacity: 1; }
  100% {
    -moz-transform: rotate(360deg);
    opacity: 0.2; } }

@-ms-keyframes spin {
  0% {
    -ms-transform: rotate(0deg);
    opacity: 0.2; }
  50% {
    -ms-transform: rotate(180deg);
    opacity: 1; }
  100% {
    -ms-transform: rotate(360deg);
    opacity: 0.2; } }

@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg);
    opacity: 0.2; }
  50% {
    -o-transform: rotate(180deg);
    opacity: 1; }
  100% {
    -o-transform: rotate(360deg);
    opacity: 0.2; } }

@keyframes spin {
  0% {
    transform: rotate(0deg);
    opacity: 0.2; }
  50% {
    transform: rotate(180deg);
    opacity: 1; }
  100% {
    transform: rotate(360deg);
    opacity: 0.2; } }

@-webkit-keyframes blink {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }

@-moz-keyframes blink {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }

@keyframes blink {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }

.ocr-jobs-title {
  float: left; }

.reload-btn {
  border-color: #4289ef;
  margin-left: 15px;
  padding: 4px 5px;
  line-height: normal;
  float: left; }
  .reload-btn .glyphicon-repeat {
    font-size: 12px;
    color: #4289ef;
    top: 2px; }

.reload-msg {
  float: left;
  padding: 3px 0 0 10px; }

.end-block {
  clear: both; }

.ocr-jobs-list table {
  min-width: 900px; }
  .ocr-jobs-list table.table-headerfixed .col-status {
    width: 5%; }
    .ocr-jobs-list table.table-headerfixed .col-status .glyphicon {
      font-size: 20px;
      margin: 0px 2px; }
      .ocr-jobs-list table.table-headerfixed .col-status .glyphicon.glyphicon-time {
        color: grey; }
      .ocr-jobs-list table.table-headerfixed .col-status .glyphicon.glyphicon-refresh {
        color: #4CAF50; }
      .ocr-jobs-list table.table-headerfixed .col-status .glyphicon.glyphicon-record {
        color: #FF9800; }
      .ocr-jobs-list table.table-headerfixed .col-status .glyphicon.glyphicon-ok-circle {
        color: #03A9F4; }
      .ocr-jobs-list table.table-headerfixed .col-status .glyphicon.glyphicon-remove {
        color: #FF0000; }
  .ocr-jobs-list table.table-headerfixed .col-aciton {
    width: 10%; }
  .ocr-jobs-list table.table-headerfixed .col-jobname {
    width: 25%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
  .ocr-jobs-list table.table-headerfixed .col-registdate {
    width: 18%; }
  .ocr-jobs-list table.table-headerfixed .col-formname {
    width: 27%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
  .ocr-jobs-list table.table-headerfixed .col-register {
    width: 15%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
  .ocr-jobs-list table .icon-lms {
    height: 20px;
    width: 20px;
    margin-bottom: 10px; }
  .ocr-jobs-list table .icon-margin {
    margin-left: 58px; }

.job-create h4 {
  margin-top: 30px; }

.job-create .bold {
  font-size: 16px; }

.job-create .alert {
  width: 100%;
  margin: 10px 0px; }

.job-create .main-title .required {
  text-align: right; }

.job-create .select-form .btn-area button {
  position: relative;
  float: right;
  top: -5px; }

.job-create .csvfile-form a {
  font-size: 14px;
  float: right; }

.job-create .csvfile-form .file-name {
  font-size: 18px; }

.job-create .form-list table {
  min-width: 650px; }
  .job-create .form-list table.table-headerfixed .col-formname {
    width: 20%; }
  .job-create .form-list table.table-headerfixed .col-formtext {
    width: 32%; }
  .job-create .form-list table.table-headerfixed .col-updater {
    width: 15%; }
  .job-create .form-list table.table-headerfixed .col-updatedate {
    width: 23%; }
  .job-create .form-list table.table-headerfixed .col-action {
    width: 10%; }

.job-create .ocr-title .glyphicon, .job-create .select-form .glyphicon, .job-create .scanfile-form .glyphicon, .job-create .csvfile-form .glyphicon {
  font-size: 10px;
  margin: 0 5px 0 0; }

.job-create .action-icon .glyphicon {
  margin: 0px 15px;
  font-size: 20px; }

.job-create #scanfile-list li {
  padding: 0px; }

.job-create #scanfile-list .input-group {
  padding: 5px; }
  .job-create #scanfile-list .input-group .file-name {
    float: left;
    margin: 5px 10px; }
  .job-create #scanfile-list .input-group .del-btn {
    float: right;
    margin: 0px 15px; }

.ocr-result-edit table.ocr-result-tbl {
  min-width: 1000px; }
  .ocr-result-edit table.ocr-result-tbl.table-headerfixed thead.bg-primary th {
    padding: 5px 5px 5px 10px; }
  .ocr-result-edit table.ocr-result-tbl.table-headerfixed .col-status {
    width: 4%; }
  .ocr-result-edit table.ocr-result-tbl.table-headerfixed .col-img {
    padding: 1px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
    .ocr-result-edit table.ocr-result-tbl.table-headerfixed .col-img img {
      max-height: 100%;
      max-width: 100%; }
  .ocr-result-edit table.ocr-result-tbl.table-headerfixed .col-val {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
  .ocr-result-edit table.ocr-result-tbl.table-headerfixed .col-action {
    width: 7%; }
  .ocr-result-edit table.ocr-result-tbl.table-headerfixed .bg-danger .col-status .icon-error {
    margin-left: 2px; }
  .ocr-result-edit table.ocr-result-tbl.table-headerfixed .bg-warning .col-status .icon-warn {
    margin-left: 2px; }

.ocr-result-edit .totalCount {
  text-align: right;
  padding: 0px 10px; }

.ocr-result-edit .warnCount {
  text-align: center; }

#modal-ocr-result-edit .table-responsive {
  width: auto; }

#modal-ocr-result-edit table.table-headerfixed thead.bg-primary th {
  font-size: 12px;
  padding-top: 8px; }

#modal-ocr-result-edit table.table-headerfixed .col-no {
  width: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

#modal-ocr-result-edit table.table-headerfixed .col-name {
  width: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

#modal-ocr-result-edit table.table-headerfixed tbody {
  max-height: 40vh; }

#modal-ocr-result-edit .score {
  width: 50px; }

#modal-ocr-result-edit .preview-area .form-area img {
  max-height: 65vh;
  width: 395px; }

#modal-ocr-result-edit .modal-footer {
  text-align: center;
  border-top: none; }

#modal-ocr-result-edit input.noCandidateDataList {
  width: 150px; }

#modal-ocr-result-edit .data-area .glyphicon {
  font-size: 10px;
  margin: 0 5px 0 0; }
