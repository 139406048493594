@import "mixin";

.ocr-jobs-title {
  float: left;
}

.reload-btn {
  border-color: #4289ef;
  margin-left: 15px;
  padding: 4px 5px;
  line-height: normal;
  float: left;

  .glyphicon-repeat {
    font-size: 12px;
    color: #4289ef;
    top: 2px;
  }
}

.reload-msg {
  float: left;
  padding: 3px 0 0 10px;
}

.end-block {
  clear: both;
}

.ocr-jobs-list {
  table {
    min-width: 900px;

    &.table-headerfixed {
      .col-status {
        width: 5%;

        .glyphicon {
          font-size: 20px;
          margin: 0px 2px;

          &.glyphicon-time {
            color: grey;
          }
          &.glyphicon-refresh {
            color: #4CAF50;
          }
          &.glyphicon-record {
            color: #FF9800;
          }
          &.glyphicon-ok-circle {
            color: #03A9F4;
          }
          &.glyphicon-remove {
            color: #FF0000;
          }
        }
      }
      .col-aciton {
        width: 10%;
      }
      .col-jobname {
        width: 25%;
        @include ellipsis;
      }
      .col-registdate {
        width: 18%;
      }
      .col-formname {
        width: 27%;
        @include ellipsis;
      }
      .col-register {
        width: 15%;
        @include ellipsis;
      }
    }
    
    .icon-lms {
      height:20px;
      width:20px;
      margin-bottom: 10px;
    }

    .icon-margin {
      margin-left: 58px;
    }
  }
}

.job-create {
  h4 {
    margin-top: 30px;
  }

  .bold {
    font-size: 16px;
  }

  .alert {
    width: 100%;
    margin: 10px 0px;
  }

  .main-title .required {
    text-align: right;
  }

  .select-form .btn-area button {
    position: relative;
    float: right;
    top: -5px;
  }

  .csvfile-form {
    a {
      font-size: 14px;
      float: right;
    }

    .file-name {
      font-size: 18px;
    }
  }

  .form-list {
    table {
      min-width: 650px;

      &.table-headerfixed {
        .col-formname {
          width: 20%;
        }
        .col-formtext {
          width: 32%;
        }
        .col-updater {
          width: 15%;
        }
        .col-updatedate {
          width: 23%;
        }
        .col-action {
          width: 10%;
        }
      }
    }

  }

  .ocr-title, .select-form, .scanfile-form, .csvfile-form{
    .glyphicon {
      font-size: 10px;
      margin: 0 5px 0 0;
      }
  }

  .action-icon .glyphicon {
    margin: 0px 15px;
    font-size: 20px;
  }

  #scanfile-list {
    li {
      padding: 0px;
    }

    .input-group{
      padding: 5px;

      .file-name {
        float: left;
        margin: 5px 10px;
      }

      .del-btn {
        float: right;
        margin: 0px 15px;
      }
    }

  }
}

.ocr-result-edit {
  table.ocr-result-tbl {
    min-width: 1000px;

    &.table-headerfixed {
      thead.bg-primary th {
        padding: 5px 5px 5px 10px;
      }

      .col-status {
        width: 4%;
      }
      .col-img {
        padding: 1px;
        @include ellipsis;

        img {
          max-height: 100%;
          max-width: 100%;
        }
      }
      .col-val {
        @include ellipsis;
      }
      .col-action {
        width: 7%;
      }

      .bg-danger .col-status .icon-error {
        margin-left: 2px;
      }
      .bg-warning .col-status .icon-warn {
        margin-left: 2px;
      }
    }
  }

  .totalCount {
    text-align: right;
    padding: 0px 10px;
  }

  .warnCount {
    text-align: center;
  }
}

#modal-ocr-result-edit {
  .table-responsive {
    width: auto;
  }

  table {
    &.table-headerfixed {
      thead.bg-primary th {
        font-size: 12px;
        padding-top: 8px;
      }

      .col-no {
        width: 50%;
        @include ellipsis;
      }
      .col-name {
        width: 50%;
        @include ellipsis;
      }

      tbody {
        max-height: 40vh;
      }
    }
  }

  .score {
    width: 50px;
  }

  .preview-area {
    .form-area img {
      max-height: 65vh;
      width: 395px;
    }
  }

  .modal-footer {
    text-align: center;
    border-top: none;
  }

  input.noCandidateDataList {
    width: 150px;
  }

  .data-area {
    .glyphicon {
      font-size: 10px;
      margin: 0 5px 0 0;
    }
  }
}