.stepMenuArea {
    text-align: center;
    margin-bottom: 20px;

  .stepMenu {
    width: 15%;
    min-width: 215px;
    margin: 3px 0px;
    color: #4289ef;
    border-color: #4289ef;

    &.active, &.subActive {
      color: white;
      background-color: #4289ef;
      border-color: white;
    }
  }

  .stepAllow {
    width: 2%;
    color: #4289ef;
  }

}
