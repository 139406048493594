@import "mixin";

.form-list {
  table {
    min-width: 800px;

    thead {
      color: white;
    }

    &.table-headerfixed {
      .col-formname {
        width: 20%;
        @include ellipsis;

        .popover {
          max-width: none;
          width: auto;
          word-break: break-all;
        }
      }
      .col-formtext {
        width: 30%;
        @include ellipsis;
      }
      .col-updater {
        width: 15%;
        @include ellipsis;
      }
      .col-updatedate {
        width: 20%;
      }
      .col-action {
        width: 15%;
      }
    }
  }
}

.change-rect {
  margin-top: 20px;

  a span.required {
    font-size: 14px;
  }

  .rect-item {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    border: 1px solid #ddd;
    background-color: white;
    color: #555;
    text-decoration: none;
  }

  .target-rect {
    border: 4px solid red;
    background-color: #f2dede;
  }

  .required-rect {
    background-color: #fcf8e3;
  }

  .icon-warn {
    float: right;
  }

  .cancel-rect {
    float: right;
    position: relative;
    top: -7px;
  }
  
  .rect-fixed-pos {
    height:42px;
    padding: 11px 0px;
    margin-bottom: -1px;
  }
  
  .select-rect {
    height:48px;
    padding: 14px 0px;
    margin-bottom: -1px;
  }
}

.form-file-select {
  .alert {
    width: 100%;
    margin-bottom: 5px;
  }

  .preview-area {
    .form-area {
      clear: both;

      .preview-nav::before, ::after {
        clear: none !important;
      }

      .rotate-button {
        padding-bottom: 8px;
        float: right;
        outline: none;

        .rotate-icon-area{
          position: relative;

          .rotate-icon {
            position: relative;
            display: inline-block;
            padding: 0 0 0 16px;
            color: #fff;
            vertical-align: middle;
            text-decoration: none;
            font-size: 15px;
          }
          .rotate-icon::before {
            position: absolute;
            margin: auto;
            content: "";
            vertical-align: middle;
            top: 13px;
            bottom: 0;
            left: 12px;
            box-sizing: border-box;
            width: 5px;
            height: 5px;
            border: 7px solid transparent;
            border-left: 10px solid #fff;
            -webkit-transform: rotate(40deg);
            transform: rotate(40deg);
          }
          .rotate-icon::after {
            position: absolute;
            top: 5px;
            bottom: 0;
            margin: auto;
            content: "";
            vertical-align: middle;
            left: -1px;
            border: 0 solid transparent;
            border-top: 5px solid #fff;
            border-radius: 10px 0 0;
            width: 16px;
            height: 10px;
            -webkit-transform: rotate(34deg);
            transform: rotate(34deg);
          }

          @-moz-document url-prefix() {
            .rotate-icon::before {
              top: 5px;
            }
          }
          @-moz-document url-prefix() {
            .rotate-icon::after {
              top: 2px;
            }
          }

          .rotate-angle {
            position: absolute;
            color: #fff;
            font-weight: 500;
            font-size: 11px;
            top: -2px;
            left: 4px;
            text-shadow: 1px 1px 0 #337ab7,
                         -1px 1px 0 #337ab7;
          }
        }
      }

      .tab-content {
        clear: both;
      }
    }
  }
  .btn-area {
    clear: both;
  }
}

.input-area, .confirm-area {
  .glyphicon {
    font-size: 10px;
    margin: 0 5px 0 0;
  }
}

.form-confirm {
  .preview-area .form-area img {
    max-height: 100%;
    max-width: 100%;
  }

  .alert {
    width: 100%;
    margin-bottom: 5px;
  }

  .form-area {
    user-select: none; /* CSS3 */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Safari、Chromeなど */
    -ms-user-select: none; /* IE10から */
  }

  .bg-img{
    width: 550px;
    border:solid #999999 1px;
    position: relative;
  }

  .bg-canvas{
    background-color: transparent;
    position: absolute;
    top: 0px;
    left: 0px;
  }
}

.form-edit {
  .preview-area .form-area img {
    max-height: 100%;
    max-width: 100%;
  }

  .alert {
    width: 100%;
    margin-bottom: 5px;
  }

  .form-area {
    user-select: none; /* CSS3 */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Safari、Chromeなど */
    -ms-user-select: none; /* IE10から */
  }

  .bg-img{
    width: 550px;
    border:solid #999999 1px;
    position: relative;
  }

  .bg-canvas{
    background-color: transparent;
    position: absolute;
    top: 0px;
    left: 0px;
  }
}

.form-preview {
  .preview-area .forms {
    width: 50%;
    float: left;
  }
}

.form-file-name {
  padding-top: 8px;
  @include ellipsis;
}