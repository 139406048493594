/* アニメーション */
// 回転
@mixin spin($time:1.5s) {
  -webkit-animation: spin $time linear infinite;
  -moz-animation: spin $time linear infinite;
  -ms-animation: spin $time linear infinite;
  -o-animation: spin $time linear infinite;
  animation: spin $time linear infinite;
}

@-webkit-keyframes spin {
  0% {-webkit-transform: rotate(0deg); opacity: 0.2;}
  50% {-webkit-transform: rotate(180deg); opacity: 1;}
  100% {-webkit-transform: rotate(360deg); opacity: 0.2;}
}
@-moz-keyframes spin {
  0% {-moz-transform: rotate(0deg); opacity: 0.2;}
  50% {-moz-transform: rotate(180deg); opacity: 1;}
  100% {-moz-transform: rotate(360deg); opacity: 0.2;}
}
@-ms-keyframes spin {
  0% {-ms-transform: rotate(0deg); opacity: 0.2;}
  50% {-ms-transform: rotate(180deg); opacity: 1;}
  100% {-ms-transform: rotate(360deg); opacity: 0.2;}
}
@-o-keyframes spin {
  0% {-o-transform: rotate(0deg); opacity: 0.2;}
  50% {-o-transform: rotate(180deg); opacity: 1;}
  100% {-o-transform: rotate(360deg); opacity: 0.2;}
}
@keyframes spin {
  0% {transform: rotate(0deg); opacity: 0.2;}
  50% {transform: rotate(180deg); opacity: 1;}
  100% {transform: rotate(360deg); opacity: 0.2;}
}

// 点滅
@mixin blinking($time:1.5s){
  -webkit-animation:blink $time ease-in-out infinite alternate;
  -moz-animation:blink $time ease-in-out infinite alternate;
  animation:blink $time ease-in-out infinite alternate;
}

@-webkit-keyframes blink{
  0% {opacity:0.2;}
  100% {opacity:1;}
}

@-moz-keyframes blink{
  0% {opacity:0.2;}
  100% {opacity:1;}
}

@keyframes blink{
  0% {opacity:0.2;}
  100% {opacity:1;}
}


// 表示幅を超える文字を「...」で表示
@mixin ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
