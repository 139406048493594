.user-form {
  .form-horizontal {
    max-width: 800px;
    padding: 15px;
    margin: 0 auto;
  }
}

.user-create-title {
  min-height: 20px;

  h3 {
    margin: 0px;
  }
}

.input-invalid {
  border: solid 2px red;
}
