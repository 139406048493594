.form-login {
  min-width: 330px;
  padding: 15px;
  margin: 0 auto;

  .registration {
    text-align: center;
  }
}

.input-area {
  .glyphicon {
    font-size: 10px;
    margin: 0 5px 0 0;
  }
}
