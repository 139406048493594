body {
  padding-top: 70px;
}

.horizontal-collapse {
  height: 70px;
}
.navbar-collapse.in {
  overflow-y: hidden;
}

.username-border {
  border-top: 1.5px dashed #e7e7e7;
  padding-top: 10px;
}

.nav-active {
  border-bottom: medium solid #AAAAAA;
}

.navbar-fixed-top {
    padding-right: 10px;
}
